.join-form {
  z-index: 1;
  position: relative;
  height: 240px;
  flex-shrink: 0;
}@media (max-width: 767px) {.join-form {
    width: 100%;
  }
}@media (min-width: 767px) {.join-form {
    width: 360px;
  }
}.join-form {
  /* hope firefox can support backdrop blur */
}
.join-form::before {
  z-index: 0;
  position: absolute;
  content: '';
  left: -680px;
  width: 1342px;
  top: -512px;
  height: 1024px;
  background: url('../../asset/welcome/bg1.svg') no-repeat left 350px top 150px, url('../../asset/welcome/bg2.svg') no-repeat left -150px top 160px;
}
.join-form .join-form-inner {
  z-index: 2;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(var(--white-color), var(--tw-border-opacity));
  --tw-border-opacity: 0.06;
  text-align: center;
}
.join-form .join-form-inner::before {
  z-index: -1;
  position: absolute;
  content: '';
  left: -680px;
  width: 1342px;
  top: -512px;
  height: 1024px;
  filter: blur(15px);
  background-blend-mode: normal;
  /* use constant to avoid mobile scorll flick */
  background: url('../../asset/welcome/bg1.svg') no-repeat left 350px top 150px, url('../../asset/welcome/bg2.svg') no-repeat left -150px top 160px, #000918;
}
@media (max-width: 767px) {
  .join-form::before {
    background: url('../../asset/welcome/bg1.svg') no-repeat left 550px top 200px, url('../../asset/welcome/bg2.svg') no-repeat left 50px top 210px;
  }
  .join-form .join-form-inner::before {
    /* use constant to avoid mobile scorll flick */
    background: url('../../asset/welcome/bg1.svg') no-repeat left 550px top 200px, url('../../asset/welcome/bg2.svg') no-repeat left 50px top 210px, #000918;
  }
}
