@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './antdOverrider.less';

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

@btn-border-radius-base: 0;@btn-height-lg: 56px;@btn-font-size-lg: 18px;@btn-padding-horizontal-lg: 0;@input-height-base: 40px;@input-height-lg: 56px;@modal-body-padding: 0;@slider-rail-background-color: rgb(var(--gray-slider-color));@slider-rail-background-color-hover: rgb(var(--gray-slider-color));@slider-dot-border-color: rgb(var(--gray-slider-color));@slider-dot-border-color-active: rgb(var(--gray-slider-color));@input-placeholder-color: rgba(var(--gray-dark-color), 0.3);@input-border-color: rgba(var(--gray-dark-color), 0.2);@link-color: inherit;@link-hover-color: inherit;@link-active-color: inherit;