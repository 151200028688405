.ant-slider-disabled.vote-slide .ant-slider-dot {
  @apply border-gray-slider !important;
}

.vote-slide {
  .ant-tooltip-inner {
    padding: 0;
    background: none;
    box-shadow: none;
  }

  .vote-slider-tip {
    @apply relative w-[153px] h-[100px] text-center text-primary top-4;
  }

  .ant-tooltip-arrow {
    display: none !important;
  }

  .ant-slider-track {
    display: none;
  }

  .ant-slider-dot {
    width: 13px;
    height: 13px;
    @apply bg-gray-slider !important;
  }

  .ant-slider-handle {
    @apply bg-primary border-0 h-24 w-24 !important;
    top: 0px;
  }

  .ant-slider-mark-text {
    @apply text-gray-dark;
  }
}

.vote-slide:not(.ant-slider-vertical) {
  .ant-slider-rail {
    height: 6px;
  }

  .ant-slider-dot {
    top: -3px;
  }

  .ant-slider-mark {
    top: 18px;
  }
}

.vote-slide.ant-slider-vertical {
  left: 65px;

  .ant-slider-mark-text {
    left: -80px;
  }

  .ant-slider-dot,
  .ant-slider-handle {
    left: 0;
  }
}
