// fix antd icon center
svg {
  vertical-align: baseline;
}

// Spin
.ant-spin-nested-loading,
.ant-spin-container {
  height: 100%;
}

// Form
.ant-form-item-extra {
  color: rgb(var(--gray-dark-color));
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 13px;
  line-height: 1;
  padding-top: 8px;
}

// Message
.ant-message-notice-content {
  border-radius: 99px;
  padding: 10px 18px;
  line-height: 16px;
}
